import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate } from 'react-router-dom';
import { baseInstance, authInstance, fileAuthInstance } from '../../../modules/api/api';
import dateString from '../../../modules/currentTime/currentTime';
import inputData from './../../../modules/inputData/inputData'


export default function Board(){
    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리

    const navigate = useNavigate();
    const [clickedData, setClickedData] = useState(null);
    const [clickedDataMo, setClickedDataMo] = useState(null);
    // Change Object
    let [dataObj, setDataObj] = useState({});

    // Click category
    const [isClicked, setIsClicked] = useState(0);
    const clickMore = (el1) => {
        setIsClicked(parseInt(el1));
        clickPageNumber(1) // 페이지 초기화
        setArrowNum(0)
        setArr([]);
    }

    // Pagination
    const onepage =  displayMo.matches ? 5 : 12;
    const totalPage = clickedData?.pages
    const pageArr = [];
    for (let i = 0; i < totalPage; i++) {
        pageArr.push(i+1);
    } 
    const [currentPage, setCurrentPage] = useState(1);
    const clickPageNumber = (e) => {
        setCurrentPage(e);
    }
    const [arrowNum, setArrowNum] = useState(0);
    const batchOfPages = 5;
    const clickNextArrow = (e) => {
        // console.log(totalPage,'t')
        // console.log(batchOfPages, 'b')
        if (arrowNum < (totalPage - batchOfPages)) {
            setArrowNum(arrowNum + batchOfPages);
        }
    }
    const clickPrevArrow = (e) => {
        if (!arrowNum == 0) {setArrowNum(arrowNum - batchOfPages);}
    }

    // Pagination (more type)
    const clickMoreContents = () => {
        setCurrentPage(currentPage + 1);
    }

    // [API] Get data
    const [arr, setArr] = useState([]);
    const fetchData = async () => {
        try {
            if (isClicked) { // == 1~3
                const resp = await baseInstance.get(`news/category/?page=${currentPage}&size=${onepage}&category=${isClicked - 1}`)
                // PC
                setClickedData(resp.data)
                // Mobile
                arr.push(...resp.data.items);
                setClickedDataMo([...arr]);
            } else { // == 0
                const resp = await baseInstance.get(`news/all/?page=${currentPage}&size=${onepage}`)
                // PC
                setClickedData(resp.data)
                // Mobile
                arr.push(...resp.data.items);
                setClickedDataMo([...arr]);
            }
        } catch (err) {
            console.log('Error >>', err) 
        }
    }
    useEffect(() => {
        fetchData();
    }, [isClicked, currentPage])
    
    // search contents
    const searchContents = async (keyword) => {
        try {
            if (isClicked) { // == 1~3
                const resp = await baseInstance.get(`news/search/${keyword}?page=${currentPage}&size=${onepage}&category=${isClicked - 1}`)
                setClickedData(resp.data)

                // Mobile
                // console.log(resp.data)
                arr.push(...resp.data.items);
                // console.log(arr);
                setClickedDataMo([...arr]);
            } else { // == 0
                const resp = await baseInstance.get(`news/search/${keyword}?page=${currentPage}&size=${onepage}`)
                setClickedData(resp.data)
                
                // Mobile
                arr.push(...resp.data.items);
                setClickedDataMo([...arr]);
            }
            inputData(setDataObj,'keyword', '')
        } catch (err) {
            if (err.response.status == 404) {
                setClickedData([])
                console.log('검색 결과가 존재하지 않습니다.')
            }
            console.log('Error >>', err) 
        }
    }

    return(
        <>
            <div className={`section-one board`}>
                <div className='section-one__con board__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title'>
                            이은소식
                        </div>
                        <select
                            className='board-mo__select none-pc'
                            onChange={(e) => {clickMore(e.target.value)}}
                            // onClick={(e) => {clickMore(e.target.value)}}
                        >
                            {['전체', '언론보도', 'SNS', '공지사항'].map((a,i)=>{
                                return(<option value={i}>{a}</option>)
                            })}
                        </select>
                    </div>

                    <div className='board__search-con'>
                        <select
                            className='board-mo__select'
                            onChange={(e) => {clickMore(e.target.value)}}
                            // onClick={(e) => {clickMore(e.target.value)}}
                        >
                            {['전체', '언론보도', 'SNS', '공지사항'].map((a,i)=>{
                                return(<option value={i}>{a}</option>)
                            })}
                        </select>

                        <div className='board-mo__search-wrap'>
                            <input
                                type= 'text'
                                onChange={(e) => {inputData(setDataObj,'keyword',e.target.value)}}
                                placeholder='검색어를 입력해 주세요.'
                                value={dataObj.keyword}
                            />
                            <img
                                src='/icons/search.png'
                                onClick={() => {searchContents(dataObj.keyword)}}
                            />
                        </div>
                    </div>

                    {/* <ul className='submenu'>
                        {['전체', '언론보도', 'SNS', '공지사항'].map((a,i) => {
                            return (
                                <li
                                className={isClicked == i ? 'submenu-clicked' : ''}
                                onClick={() => {clickMore(i)}}
                                >{a}</li>
                            )
                        })}
                    </ul> */}

                    <div className='board-mo__search-wrap none-pc'>
                        <input
                            type= 'text'
                            onChange={(e) => {inputData(setDataObj,'keyword',e.target.value)}}
                            placeholder='검색어를 입력해 주세요.'
                            value={dataObj.keyword}
                        />
                        <img
                            src='/icons/search.png'
                            onClick={() => {searchContents(dataObj.keyword)}}
                        />
                    </div>

                    <ul className='board__box-con'>
                        {/* {clickedDataMo?.map((a,i) => { */}
                        {clickedData?.items?.map((a,i) => {
                            return (
                                <li
                                className={`board__box-item ${a.images ? 'sns-item' : ''}`}
                                onClick={() => {navigate(`/board/detail/${a._id}`)}}>
                                    <div className='board__box-menu'>
                                        {
                                            a.category == 0 ? '언론보도'
                                            : (a.category == 1 ? 'SNS'
                                            : '공지사항')
                                        }
                                    </div>
                                    <div className='board__box-title'>{a.title}</div>
                                    <div className='board__box-date'>{dateString(new Date(a.date))}</div>
                                    
                                    <div className='board__box-img-con' style={{display: a.images ? '' : 'none'}}>
                                        <div className='board__bg-gray'></div>
                                        <img className='board__box-img' src={a.images?.urls[0]} />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    {/* {console.log('mo',clickedDataMo)}
                    {console.log('pc',clickedData)} */}

                    <div className={`board__page-number`}>
                        <div className='board__page-arrow' onClick={()=>{clickPrevArrow()}}>&#8249;</div>
                        {pageArr?.slice(arrowNum,(arrowNum + batchOfPages)).map((a, i) => {
                            return(
                                <div
                                    className='board__page-number-item'
                                    style={{color: a == currentPage ? '#fff' :'',
                                            width: a == currentPage ? '25px' :'',
                                            height: a == currentPage ? '25px' :'',
                                            background: a == currentPage ? '#13AC67' : ''}}
                                    onClick={() => {clickPageNumber(a)}}>
                                        {a}
                                </div>
                            )})}
                        <div className='board__page-arrow' onClick={()=>{clickNextArrow()}}>&#8250;</div>
                    </div>

                    {/* <div className='board-more'>
                        <span onClick={clickMoreContents}>더보기</span>
                    </div> */}
                </div>
            </div>

        </>
    )
}