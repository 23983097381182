import { useEffect, useState } from 'react';
import './../../../App.css';
import Submenu from '../../../components/Submenu';

export default function Ceo(){
    localStorage.setItem('submenu', 'ceo')

    return(
        <>
            <Submenu />
            <div className='ceo__con'>
                <div className='ceo__txt'>
                    <div className='ceo__txt-con'>
                        <div className='ceo__txt-title'>
                            <span className='quotes'>&ldquo;&nbsp;</span><span>가치가 즐거운 교육</span>으로<br/>
                            <span className='mg-left'>이어지는 사회</span>를 만들겠<br className='br-mo' />습니다.<span className='quotes'>&nbsp;&rdquo;</span>
                        </div>
                        <div className='ceo__txt-sub'>
                            2021년 국방부 장병인성교육 온라인 교육 개발으로 출발한 이은교육은 <br className='br-pc' />
                            이제 에듀테크를 기반으로 신기술을 교육에 접목하여 <br className='br-pc' />
                            새로운 교육 방향을 제시하는 회사로 도약하고자 합니다.<br/><br/>

                            4차 산업혁명시대로 접어들면서 나날이 새로운 기술이 탄생하고 있습니다. <br className='br-pc' />
                            기술이 탄생하고 융합하는 시대에 교육 또한 변화해야합니다.<br/><br/>

                            이은교육은 그동안 경험했던 교육 현장의 니즈와 콘텐츠, <br className='br-pc' />
                            기술 결합 역량을 기반으로 <br className='br-pc' />
                            새로운 개념의 교육을 제시하고 있습니다.<br/><br/>

                            온라인 교재, 매너로디, 하이터치 뮤직, 고객의 니즈의 맞는 학습 콘텐츠 개발을 넘어 <br className='br-pc' />
                            해외 수출에 이르기까지 <br className='br-pc' />
                            이은교육은 가치 기반 경영을 바탕으로 지속가능한 교육을 위해 나아갈 것을 약속드립니다.<br/><br/>

                            <span>이은교육 CEO 조수빈</span>
                        </div>
                    </div>
                </div>
                <div className='ceo__img-wrap'>
                    <img src='/images/profile.png' />
                </div>
            </div>
        </>
    )
}