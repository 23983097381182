import { useEffect, useState } from 'react';
import './../../../App.css';
import Submenu from '../../../components/Submenu';

export default function Service(){
    localStorage.setItem('submenu', 'service')

    return(
        <>
            <Submenu />

            
            <div className='section-one service'>
                <div className='section-one__con'>

                    <ul className='service__box-con'>
                        <li>
                            <div className='service__box-title'>
                                우리 기업/기관에 딱 맞는<br/>
                                교육 프로그램이 필요해요!
                            </div>
                            <div className='service__box-sub'>
                                교육 과정 및 커리큘럼 개발<br/>
                                온라인 강의 개발<br/>
                                교재 개발 및 출판<br/>
                                신기술 융합 교육 개발
                            </div>
                            <img src='/icons/service01.png' />
                        </li>

                        <li>
                            <div className='service__box-title'>
                                우리기업/기관 구성원을 위한 <br className='br-pc'/>
                                역량 강화 <br className='br-mo'/>교육 프로그램을 <br className='br-pc'/>
                                의뢰하고 싶어요!
                            </div>
                            <div className='service__box-sub'>
                                온/오프라인 교육<br/>
                                1회성 교육 (특강, 캠프)<br/>
                                정기 교육 등
                            </div>
                            <img src='/icons/service02.png' />
                        </li>

                        <li>
                            <div className='service__box-title'>
                                하이터치뮤직을 <br className='br-pc'/>사용하고 싶어요!
                            </div>
                            <div className='service__box-sub'>
                                아동 관련 기관, 기업<br/>
                                공연장, 전시관, 과학관 등
                            </div>
                            <div className='service__box-more'>
                                <a href='https://www.hitouchmusic.com/'>하이터치뮤직홈페이지로이동</a> &gt;
                            </div>
                            <img src='/icons/service03.png' />
                        </li>

                        <li>
                            <div className='service__box-title'>
                                매너로디를 사용하고 싶어요!
                            </div>
                            <div className='service__box-sub'>
                                청각장애인과 가족<br/>
                                장애인 관련 기관, 기업<br/>
                                공연장, 전시관, 과학관<br/>
                                정부부서/기관 등
                            </div>
                            <img src='/icons/service04.png' />
                        </li>

                        <li>
                            <div className='service__box-title'>
                                교재를 출판하고 싶어요<br/>
                                나의 이야기를 들려주고 싶어요
                            </div>
                            <div className='service__box-sub'>
                                출판사 이어드림
                            </div>
                            <img src='/icons/service05.png' />
                        </li>

                        <li>
                            <div className='service__box-title'>
                                모든 관련 문의는
                            </div>
                            <div className='service__box-sub'>
                                <span>이메일</span><br/>
                                admin@eunedu.co.kr
                            </div>
                            <div className='service__box-sub'>
                                <span>전화</span><br/>
                                02-975-0412
                            </div>
                            <img src='/icons/service06.png' />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}