import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import { baseInstance, authInstance } from './../../../modules/api/api';
import currentTime from './../../../modules/currentTime/currentTime'




export default function BoardDetail(){

    const navigate = useNavigate();
    const {id} = useParams();

    // [API] 게시물 불러오기
    const [contentData, setContentData] = useState(null);
    const [contentDate, setContentDate] = useState(null); // Date
    const getAPI = async () => {
        try {
            const resp = await baseInstance.get(`/news/${id}`)
            setContentData(resp.data);
            setContentDate(new Date(resp.data.date));
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        getAPI();
    }, [])

    return(
        <>
            <div className='section-page'>
                
            
                <div className='board-detail'>
                    <div className='board-detail__con'>
                        <div className='board-detail__header'>
                            <div className='board-detail__header-title'>
                                {contentData?.title}
                            </div>
                            <div className='board-detail__header-info'>
                                <ul>
                                    <li>
                                        {
                                            contentData?.category == 0 ? '언론보도'
                                            : (contentData?.category == 1 ? 'SNS'
                                            : '공지사항')
                                        }
                                    </li>
                                    <li>{currentTime(contentDate)}</li>
                                </ul>
                            </div>
                            <div className='board-detail__content-con'>
                                {/* textarea 내용의 엔터인식을 위해 <pre></pre>로 감싸기 */}
                                <div className='board-detail__content-item'>
                                    <pre dangerouslySetInnerHTML={{__html: contentData?.contents}}>
                                    </pre>
                                </div>
                            </div>
                            <div className='board-detail__btn-list'>
                                <a href='/board'>이은소식 목록</a>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </>
    )
}