import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './../App.css';

export default function Header(props){
    const thisPage = useLocation().pathname;

    const [scrollPosition, setScrollPosition] = useState(null);
    const [clickHamburger, setClickHamburger] = useState(null);
    const [clickMenu, setClickMenu] = useState(null);

    // 스크롤 위치 인식
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    // 무한 스크롤 방지하기 위해서 100ms마다 스크롤 위치 체크
    useEffect(()=>{
        const timer = setTimeout(window.addEventListener('scroll', updateScroll), 100)       
        return () => {
            clearTimeout(timer);
        };
    });

    // 햄버거 버튼 클릭시 메뉴 보이기
    const showList = () => {
        setClickHamburger(!clickHamburger);
    };

    // 모바일 내 메뉴 클릭시 서브 메뉴 보이기
    const showSubMenu = (e) => {
        setClickMenu(e);
    }


    // sub-menu
    const [isHovering, setIsHovering] = useState(0);
    const handleMouseOver = (index) => {
      setIsHovering(index);
    };
    const handleMouseOut = () => {
      setIsHovering(0);
    };

    console.log(isHovering)



    return (
        <>
            <header className={`header + ${scrollPosition < 50 ? "" : "header-changed"} + ${clickHamburger ? "header-click" : "header-unclick"}`}>
                <div className='header__con'>
                    <ul className='header__logo-con'>
                        <li className='header__logo'>
                            <a href='/'><img src='/images/logo.png' /></a>
                        </li>
                        <li className='header__hamburger'><a><img src={clickHamburger ? `/img/btn_x.png` : `/icons/btn_hamburger.png`} style={{width: '24px'}} onClick={showList}/></a></li>
                    </ul>
                    
                    <ul
                    className='header__category-con'
                    onMouseOver={() => handleMouseOver(999)}
                    onMouseOut={handleMouseOut}
                    >
                        <li>
                            <a
                            className= {isHovering == 1 ? 'header__category-after' : ''}
                            onClick={() => showSubMenu(1)}
                            >회사소개</a>
                        </li>
                        <li>
                            <a href='/board'>이은소식</a>
                        </li>
                        <li>
                            <a href='/contact'>Contact</a>
                        </li>
                    </ul>

                    <div className='header__btn-wrap'>
                        <a href='https://smartstore.naver.com/eunedumarket'>네이버 스토어</a>
                    </div>

                    <div
                    className={`header-sub__con ${isHovering ? 'header-sub__con-after' : ''}`}
                    >
                        <ul
                        className={`header-sub__category-con`}
                        onMouseOver={() => handleMouseOver(1)}
                        onMouseOut={handleMouseOut}
                        >
                            <li><a href='/company/introduction'>회사소개 및 연혁</a></li>
                            <li><a href='/company/ceo'>인사말</a></li>
                            <li><a href='/company/service'>사업소개</a></li>
                        </ul>
                    </div>
                </div>
            </header>

            <header className={`header-mo-bar  + ${scrollPosition < (thisPage == '/' ? 50 : 0) ? "" : "header-mo-bar-changed"} + ${clickHamburger ? "header-mo-bar__clicked" : "k"}`}>
                <div className='header-mo-bar__con'>
                    <ul className='header-mo-bar__logo-con'>
                        <li className='header-mo-bar__logo'>
                            <a href='/'><img src= {clickHamburger ? '/images/logo.png' : '/images/logo_white.png'} /></a>
                        </li>
                        <li className='header-mo-bar__hamburger'><a><img src={clickHamburger ? `/icons/btn_x.png` : `/icons/btn_hamburger.png`} onClick={showList}/></a></li>
                    </ul>
                </div>
            </header>
            {/* 인트로페이지에서만 높이 없음 */}
            <div className={thisPage == '/' ? '' : 'header-mo-bar-block'}></div>

            <div className={`header-mo ${clickHamburger ? 'top-height' : '' }`}>
                <div className='header-mo__btn-wrap'>
                    <a href='https://smartstore.naver.com/eunedumarket'>
                        네이버 스토어
                    </a>
                </div>

                <ul className='header-mo__category-con'>
                    <li className='header-mo__dropdown'>
                        <a>
                            회사소개
                        </a>
                        <ul>
                            <li><a href='/company/introduction'>
                                회사 소개 및 연혁
                            </a></li>
                            <li><a href='/company/ceo'>
                                인사말
                            </a></li>
                            <li><a href='/company/service' >
                                사업소개
                            </a></li>
                        </ul>
                    </li>
                    <li>
                        <a href='/board'>
                            이은소식
                        </a>
                    </li>
                    <li>
                        <a href='/contact'>
                            Contact
                        </a>
                    </li>
                </ul>
            </div>

            <div className='header-block'></div>
        </>
    )
}
