import { useEffect, useState } from 'react';
import './../../../App.css';
// GoogleMap
import GoogleMap from '../../../components/googleMap/GoogleMap';


export default function Contact(){

    return(
        <>
            <div className={`section-one contact`}>
                <div className='section-one__con contact__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title'>
                            <span>사람을 위한 <br className='br-mo' />교육을 만드는 곳</span><br/>
                            이은교육 오시는 길
                        </div>
                    </div>

                    <div className='contact__content-con'>
                        <div className='contact__map-wrap'>
                            <GoogleMap el1={37.629017} el2={127.090789} name={'본사'} />
                        </div>
                        <div className='contact__txt-con'>
                            <li>이은교육 본사</li>
                            <ul>
                                <li>주소</li>
                                <li>서울시 노원구 화랑로 621 서울여자대학교 고명우기념관 311호, 313호</li>
                            </ul>
                            <ul>
                                <li>대표전화</li>
                                <li>02-975-0412</li>
                            </ul>
                            <ul>
                                <li>Fax</li>
                                <li>0504-032-3933</li>
                            </ul>
                            <ul>
                                <li>비즈니스 협력 문의</li>
                                <li>admin@eunedu.co.kr</li>
                            </ul>
                        </div>
                    </div>

                    <div className='contact__content-con'>
                        <div className='contact__map-wrap'>
                            <GoogleMap el1={37.547750} el2={127.061272} name={'성수사무소'} />
                        </div>
                        <div className='contact__txt-con'>
                            <li>이은교육 성수사무소</li>
                            <ul>
                                <li>주소</li>
                                <li>서울시 성동구 광나루로 286 아인빌딩 9층 소셜캠퍼스온서울 1</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}