import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate } from 'react-router-dom';
import { baseInstance, authInstance, reAuthInstance, fileAuthInstance } from '../../../modules/api/api';
import Editor from './../../../components/EditorComponent/EditorComponent';
import inputData from './../../../modules/inputData/inputData'


export default function BoardWrite(){
    const navigate = useNavigate();
    const category = localStorage.getItem('admin_selected')

    // Change Object
    let [dataObj, setDataObj] = useState({
        'title' : localStorage.getItem('recent_title') || '',
        'content' : localStorage.getItem('recent_content') || ''
    });

    // Refresh Token
    const reAuthAPI = async () => { 
        try {
            const resp = await reAuthInstance.get('/admin/refresh')
            .then ((resp) => {
                localStorage.setItem('access_token', resp.data.access_token)
            })
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        reAuthAPI();
    }, [])

    // Upload files API
    const uploadFiles = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        const nameArray = [];
        for (let i = 0; i < e.target.files.length; i++) { // 반복문으로 여러 파일을 데이터에 담기
            formData.append('files', e.target.files[i])
            nameArray.push(e.target.files[i].name)
        }
        inputData(setDataObj,'filename', nameArray)
        try {
            const resp = await fileAuthInstance.post('news/files', formData)
            console.log('success >>', resp.data);
            inputData(setDataObj,'file',resp.data)
        } catch (err) {
            console.log('Error >>', err)
        }
    }

    // POST API
    const today = new Date();
    const fetchAPI = async () => {
        try {
            const resp = await authInstance.post('/news', {
                    author: '운영진',
                    title: dataObj.title,
                    contents: dataObj.content,
                    date: String(today),
                    images: dataObj.file,
                    category: category == '언론보도' ? String(0)
                            : (category == 'SNS' ? String(1)
                            : String(2)) // 공지사항
                }
            )
            localStorage.removeItem('recent_title');
            localStorage.removeItem('recent_content');
            alert('글이 등록되었습니다.')
            navigate('/admin/list')
            window.location.reload()
        } catch (err) {
            if (err.response.status === 400) {
                alert('토큰만료로 인해 재로그인이 필요합니다. (작성한 글은 임시저장 되었습니다)')
                navigate('/admin')
            } else {
                alert('서버 문제가 발생했습니다. 잠시 후 다시 시도해주세요');
            }
            console.log('Error >>', err)
            localStorage.setItem('recent_title', dataObj.title);
            localStorage.setItem('recent_content', dataObj.content);
        }
    }

    // [component] writing editor
    function onEditorChange(value) { 
        inputData(setDataObj,'content',value)
    }

    console.log(dataObj.file)

    
    return(
        <>
            <div className='admin'>
                <img src='/images/admin.jpeg' decoding='async' />
                <div className='admin-write__con'>
                    <div className='board-write__board-name'>
                        <span>{category} 작성</span>
                    </div>
                    <div className='board-write__header'>
                        <div className='board-write__header-title'>
                            <label>
                                <input
                                    type= 'text'
                                    onChange={(e) => {inputData(setDataObj,'title',e.target.value)}}
                                    className='board-write__header-input'
                                    placeholder='제목'
                                    value={dataObj.title}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='board-write__content-con'>                        
                        <div className='board-write__warning'>* 게시물에 이미지 업로드 시에는 이미지 아이콘을 눌러 업로드 (복사 붙여넣기 금지)</div>
                        <div className='board-write__content-item'>
                            {/* Do not place <Editor /> in label tag. (font-size error) */}
                            <Editor value={dataObj.content} onChange={onEditorChange} />
                        </div>
                        <div className={`board-write__content-files`}>
                            <label for="input-file2">썸네일 선택</label>
                            <span>{dataObj.file ? dataObj.filename?.map((a,i)=>{return(<span>{a}</span>)}) : '등록 전'}</span>

                            <input
                            type= 'file'
                            onChange={(e) => {uploadFiles(e)}}
                            id='input-file2'
                            // multiple
                            />
                        </div>

                        

                        <div className='board-write__btn-submit'>
                            <li></li>
                            <span onClick={()=>{fetchAPI()}}>등록하기</span>
                        </div>
                    </div>
                </div>
            </div>
       </>
    )
}