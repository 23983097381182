import { useEffect } from 'react';
import './../App.css';

function Footer(){

    useEffect(() => {
        return () => {
        };
    }, [])

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <div className='footer__logo'>
                        <img src='/images/logo_black.png' decoding="async"/>
                    </div>
                    <hr/>
                    <div className='footer__txt-con'>
                        <div className='footer__txt01'>
                            이은교육주식회사 | (예비)사회적기업, 여성기업, 기업부설연구소, 이러닝사업자 보유
                        <br/>
                            주소 : 서울시 노원구 화랑로 621 서울여자대학교 고명우기념관 311호, 313호
                        <br/>
                            대표자 : 조수빈 | 대표번호 : 02-975-0412
                        <br/>
                        </div>
                        <div className='footer__txt02'>
                            Copyright Eun Education Inc. All Rights Reserved.
                        </div>
                    </div>
                    <ul className='footer__icon-con'>
                        <a href='https://www.instagram.com/e.un_edu_/'><img src='/icons/sns01.png' /></a>
                        <a href='https://www.youtube.com/@user-bx5bv3xu4s/featured'><img src='/icons/sns02.png' /></a>
                    </ul>
                    <div className='footer__law-con'>
                        <a href='/license'>오픈소스 라이선스</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;