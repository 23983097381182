import { useEffect, useState } from 'react';
import './../App.css';

export default function Submenu(){
    const thismenu = localStorage.getItem('submenu')

    return(
        <>
            <ul className='submenu'>
                <li className={thismenu == 'introduction' ? 'submenu-clicked' : ''}><a href='/company/introduction'>연혁 및 조직도</a></li>
                <li className={thismenu == 'ceo' ? 'submenu-clicked' : ''}><a href='/company/ceo'>인사말</a></li>
                <li className={thismenu == 'service' ? 'submenu-clicked' : ''}><a href='/company/service'>사업소개</a></li>
            </ul>
        </>
    )
}