import { useCallback, useEffect, useRef, useState } from 'react';
import './../App.css';
import { useNavigate } from 'react-router-dom';
// react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
//
import { baseInstance } from '../modules/api/api';
import currentTime from '../modules/currentTime/currentTime';


export default function Intro(){
    const navigate = useNavigate();

    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리

    // Slick
    let slickRef = useRef();
    const previous = useCallback(() => slickRef.current.slickPrev(), []); // Cutomize arrows
    const next = useCallback(() => slickRef.current.slickNext(), []); // Cutomize arrows
    const settings = {
        dots: true,
        // fade가 있으면 여러개의 slide 구현 안됨
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: displayMo.matches ? 1 :3,
        slidesToScroll: 1,
        // slidesToScroll: displayMo.matches ? 1 :3,
        autoplay: true,
        autoplaySpeed: 8000,
        swipe:true,
    };

    
    // Choose payment-cycle
    const [isClicked, setIsClicked] = useState(false);
    const clickCycleBtn = () => {
        setIsClicked(!isClicked);
    }

    // [API] Get data
    const [dataBoard, setDataBoard] = useState([]);
    const fetchData = async () => {
        try {
                const resp = await baseInstance.get(`news/all/?page=${1}&size=${6}`)
                setDataBoard(resp.data)
                console.log(resp.data)
        } catch (err) {
            console.log('Error >>', err) 
        }
    }
    useEffect(() => {
        fetchData();
    }, [])

    return(
        <>
            <div className='intro-hero'>
                <div className='intro-hero__bg'>
                    <video autoPlay loop muted playsInline>
                        <source src="/videos/intro.mp4" type="video/mp4" />
                    </video>
                </div>
                
                <div className='intro-hero__txt'>
                    <div className='intro-hero__txt-con'>
                        <div className='intro-hero__txt-title'>
                            사람을 위한 <br className='br-mo'/>교육을 만듭니다<br/>
                            <span>나와 세상을 잇는 <br className='br-mo'/>이은교육</span>
                        </div>
                        <div className='intro-hero__txt-sub'>
                        (예비)사회적기업, 여성기업, <br className='br-mo'/>기업부설연구소, 이러닝사업자 보유
                        </div>
                        <ul className='intro-hero__btn-con'>
                            {[['01', '교육 커리큘럼 개발'],
                            ['02', '교육 콘텐츠 개발'],
                            ['', ''],
                            ['03', '교육 기획 및 운영'],
                            ['04', '교육 관련 기술 개발'],
                            ['05', '교재 및 도서 출판']].map((a,i) => {
                                return (
                                    <li>
                                        <div>{a[0]}</div>
                                        <div>{a[1]}</div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>

                
            <div className='section-one intro-service'>
                <div className='section-one__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title color-green'>
                            <span>
                                사회공헌
                            </span>
                        </div>
                        <div className='section__txt-sub'>
                            교육의 기회와 가치 제공을 위해 <br className='br-mo'/>노력하고 있습니다.
                        </div>
                    </div>

                    <ul className='intro-service__img-con'>
                        {[['교육격차해소', '학습 봉사'],
                        ['문화예술을 활용한<br/>교육 콘텐츠 개발', '지속 가능한 교육'],
                        ['취약계층을 위한<br/>새로운 학습법', '에듀테크'],
                        ['장애아동, 장애청년<br/>기회제공', '가치 기반 경영']].map((a,i) => {
                            return (
                                <li className='intro-service__img-item' style={{backgroundImage: `url(/images/service0${i+1}.png)` }}>
                                    {/* <div className='intro-service__gradient'></div> */}
                                    <ul className='intro-service__img-txt-con'>
                                        <li dangerouslySetInnerHTML={{__html: a[0]}}></li>
                                        <li>{a[1]}</li>
                                    </ul>
                                </li>
                            )
                        })}  
                    </ul>
                </div>
            </div>

            <div className='section-one intro-board'>
                <div className='section-one__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title color-green'>
                            <span>
                                이은소식
                            </span>
                        </div>
                    </div>

                    <div className='intro-board__slick-con'>
                        {/* Customize arrows (it must be here in top position) */}
                        <div className='intro-board__slick-arrow-con'>
                            <div onClick={previous}>
                                <img
                                    src={"/icons/pre-arrow.png"}
                                    alt={"pre-arrow"}
                                />
                            </div>
                            <div onClick={next}>
                                <img
                                    src={"/icons/next-arrow.png"}
                                    alt={"next-arrow"}
                                />
                            </div>
                        </div>
                        
                        <Slider className='intro-board__slick' 
                        {...settings}
                        ref={slickRef} 
                        >
                            {dataBoard?.items?.map((a, i) => {
                                return(
                                    <li onClick={()=>{navigate(`/board/detail/${a._id}`)}}>
                                        <div>
                                            <img src={a.images ? a.images.urls[0] : '/images/news.png'} />
                                            <ul>
                                                <li>
                                                    {a.title}
                                                </li>
                                                <li className='intro-board__slick-txt' dangerouslySetInnerHTML={{__html: String(a.contents).replace(/<IMG(.*?)>/gi, '')}}>
                                                    
                                                </li>
                                                <li>{currentTime(new Date(a.date))}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    )
                                })
                            }
                        </Slider>

                        
                    </div>
                </div>
            </div>

            <div className='section-one intro-license'>
                <div className='section-one__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title color-green'>
                            교육 관련 특허<br className='br-mo'/>
                            <span>등록 3건 출원 3건</span>
                        </div>
                    </div>

                    {/* <div className='intro-license__btn-con' onClick={() => {clickCycleBtn()}}>
                        <div className='intro-license__btn' style={{left: isClicked ? '50%' : '0'}}>{isClicked ? '특허출원' : '특허등록'}</div>
                        <div className='intro-license__btn-txt'>특허등록</div>
                        <div className='intro-license__btn-txt'>특허출원</div>
                    </div> */}

                    {/* 특허등록 */}
                    <ul className='intro-license__img-con' style={{display: isClicked ? 'none' : ''}}>
                        <img src='/images/license01.jpg' />
                        <img src='/images/license02.jpg' />
                        <img src='/images/license03.jpg' />
                    </ul>

                    {/* 특허출원 */}
                    <ul className='intro-license__img-con' style={{display: isClicked ? '' : 'none'}}>
                        <img src='/images/licensed01.jpg' />
                    </ul>
                </div>
            </div>
        </>
    )
}