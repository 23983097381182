import { useEffect, useState } from 'react';
import './../../../App.css';
import Submenu from '../../../components/Submenu';

export default function Introduction(){
    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리

    localStorage.setItem('submenu', 'introduction')

    return(
        <>
            <Submenu />
            
            <div className='history__con'>
                <div className='history__txt'>
                    <div className='history__txt-con'>
                        <div className='history__txt-title'>
                            교육을 잇다<br/><span>이은교육</span>
                        </div>
                        <div className='history__txt-sub'>
                            이은교육은 <span>사람을 위한 교육을 만듭니다</span> 라는 슬로건 아래 <br className='br-pc' />
                            2021년 설립되었습니다.<br/><br/>
                            
                            미래세대 인재 육성을 위한 교육 개발과 <br className='br-pc' />
                            4차 산업혁명시대에 발맞춘 <span>스마트러닝, 에듀테크 사업</span>을 통해 <br className='br-pc' />
                            교육의 가치와 기회를  평등하게 제공하고자 노력하고 있습니다.<br/><br/>

                            총 12개 대학, 15개 사회적기업, 9개 공공기관과 <br className='br-pc' />
                            파트너관계를 맺고 있습니다.
                        </div>
                    </div>
                </div>

                <div className='history__list-con'>
                    <li>&nbsp;</li>
                    <ul>
                        <li>2024</li>
                        <ul>
                            <li>육군 55사단 문화예술기반 심리 상담, 창업교육 전담기관</li>
                        </ul>
                    </ul>
                    <ul>
                        <li>2023</li>
                        <ul>
                            <li>서울시립대학교 진로, 취업교육 개발 및 운영</li>
                            <li>창업중심대학 (초기) 창업팀 선정</li>
                            <li>㈜휴먼터치랩 취업 연계 교육 개발 및 운영</li>
                            <li>이은교육 주식회사 부설연구소 AHA콘텐츠개발연구소 설립</li>
                            <li>한국노인인력개발원 교육 개발 및 강사교육안개발</li>
                            <li>아동을 위한 음악기반 창의력 개발 교육 &lt;하이터치뮤직&gt; 개발</li>
                        </ul>
                    </ul>
                    <ul>
                        <li>2022</li>
                        <ul>
                            <li>사회적기업가 육성사업 선정</li>
                            <li>고용노동부 (예비)사회적기업지정</li>
                            <li>동명대학교 인권센터 교육 전담 기관</li>
                            <li>동원과학기술대학교 학생상담센터 교육 전담기관</li>
                            <li>서울여자대학교 창업교육센터 창업교육 위탁 운영기관</li>
                            <li>청각장애인 음악교육 어플리케이션 &lt;매너로디&gt; 론칭</li>
                            <li> ㈜쏘울잇개 유아 개물림사고 방지 교육 개발</li>
                        </ul>
                    </ul>
                    <ul>
                        <li>2021</li>
                        <ul>
                            <li>국방부 장병인성교육 4권역 온라인 교육 개발 및 운영</li>
                        </ul>
                    </ul>
                    <li>&nbsp;</li>
                </div>
            </div>

            <div className='section-one people'>
                <div className='section-one__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title color-green'>
                            조직도
                        </div>
                    </div>

                    <div className='people__img-wrap'>
                        <img src={displayMo.matches ? '/images/people_mo.png' : '/images/people.png'} />
                    </div>
                </div>
            </div>

            
        </>
    )
}