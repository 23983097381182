import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate } from 'react-router-dom';
import { baseInstance, authInstance, fileAuthInstance } from '../../../modules/api/api';
import dateString from '../../../modules/currentTime/currentTime';
import inputData from './../../../modules/inputData/inputData'

export default function AdminList(){
    const navigate = useNavigate();
    const [clickedData, setClickedData] = useState(null);

    // Check Login
    const checkLogin = () => {
        if (!localStorage.getItem('access_token')){
            alert('로그인이 필요합니다')
            navigate('/admin')
        }
    }

    // Click category
    const [isClicked, setIsClicked] = useState(0);
    const clickMore = (el1,el2) => {
        setIsClicked(el1);
        localStorage.setItem('admin_selected', el2)
        clickPageNumber(1) // 페이지 초기화
        setArrowNum(0)
    }
    useEffect(() => {
        localStorage.setItem('admin_selected', '언론보도')
    }, [])


    // Pagination
    const onepage =  6;
    const totalPage = clickedData?.pages
    const pageArr = [];
    for (let i = 0; i < totalPage; i++) {
        pageArr.push(i+1);
    }
    const [currentPage, setCurrentPage] = useState(1);
    const clickPageNumber = (e) => {
        setCurrentPage(e);
    }
    const [arrowNum, setArrowNum] = useState(0);
    const batchOfPages = 5;
    const clickNextArrow = (e) => {
        console.log(totalPage,'t')
        console.log(batchOfPages, 'b')
        if (arrowNum < (totalPage - batchOfPages)) {
            setArrowNum(arrowNum + batchOfPages);
        }
    }
    const clickPrevArrow = (e) => {
        if (!arrowNum == 0) {setArrowNum(arrowNum - batchOfPages);}
    }

    // [API] Get data
    const fetchData = async () => {
        try {
            const resp = await baseInstance.get(`news/category/?page=${currentPage}&size=${onepage}&category=${isClicked}`)
            parseInt(isClicked)
            setClickedData(resp.data)
        } catch (err) {
            console.log('Error >>', err) 
        }
    }
    useEffect(() => {
        checkLogin();
        fetchData();
    }, [isClicked, currentPage])


    // [API] Delete data
    const delData = async (id) => {
        console.log(id)
        try {
            const resp = await authInstance.delete(`/news/${id}`)
            alert('글이 삭제되었습니다')
            window.location.reload()
        } catch (err) {
            if (err.response.status === 400) {
                alert('토큰만료로 인해 재로그인이 필요합니다.')
                navigate('/admin')
            } else {
                alert('서버 문제가 발생했습니다. 잠시 후 다시 시도해주세요');
            }
            console.log('Error >>', err)
        }
    }

    return(
        <>
            <div className='admin'>
                <img src='/images/admin.jpeg' decoding='async' />
                <div className='admin-list__con'>

                    <div className='admin-list__btn-con'>
                        {['언론보도','SNS','공지사항'].map((a,i) => {
                            return(
                                <div
                                className={`${isClicked == i ? 'admin-list__btn-after' : ''}`}
                                onClick={()=>{clickMore(i,a)}}>
                                    {a}
                                </div>
                            )
                        })}
                    </div>

                    <div className={`admin-list__title ${isClicked < 5 ? '' : 'none'}`}>
                        <div>
                            작성목록
                        </div>
                        <div className='admin-list__more'>
                            <a href={`${
                                isClicked < 3 ? 'board'
                                : (isClicked < 4 ?'product'
                                : 'history')
                                }/write`} className={isClicked < 5 ? '' : 'none'}>
                                작성하기&nbsp;
                            </a>
                        </div>
                    </div>

                    {/* For Text-content */}
                    <table className={`admin-list__table ${isClicked < 5 ? '' : 'none'}`}>
                        <thead className='admin-list__thead'>
                            <tr>
                                <th className='admin-list__th01'>등록일</th>
                                <th className='admin-list__th02'>제목</th>
                                <th className='admin-list__th03'>수정</th>
                                <th className='admin-list__th03'>삭제</th>
                            </tr>
                        </thead>

                        <tbody className={`admin-list__tbody`}>
                            {clickedData?.items?.map((a, i) => {
                                return(
                                    <tr>
                                        <td>{dateString(new Date(a.date))}</td>
                                        <td style={{cursor:'pointer'}}>
                                            <a>
                                                {isClicked < 3 ? a.title
                                                : (isClicked < 4 ? a.product_name
                                                : a.year + '-' + a.month)}
                                            </a>
                                        </td>
                                        <td><a href=
                                            {isClicked < 3 ? `/admin/board/modify/${a._id}`
                                                : (isClicked < 4 ? `/admin/product/modify/${a._id}`
                                                : `/admin/history/modify/${a._id}`)}
                                            >수정</a></td>
                                        <td><a onClick={()=>{delData(a._id)}}>삭제</a></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    {/* For Image-content */}
                    {/* <div className={`admin-list__title ${isClicked == 5 ? '' : 'none'}`}>
                        <div>이미지 목록</div>
                        <div className={`admin-list__more`}>
                            <label for="input-file">추가하기</label>
                                <input
                                type= 'file'
                                onChange={(e) => uploadFiles(e)}
                                id='input-file'
                                className='none'
                                // multiple
                            />
                        </div>
                    </div>
                    <ul className={`admin-list__img-con ${isClicked == 5 ? '' : 'none'}`}>
                        {clickedData?.slice(boardFromNum,boardToNum)?.map((a,i) => {
                            return(
                                <li className='admin-list__img-wrap'>
                                    <img src={a} />
                                    <ul  className='admin-list__img-wrap-txt'>
                                        <a>
                                            <label for="change-file" onClick={()=>{findIndex(i + ((currentPage - 1) * 6))}}
                                            // 왜 findIndex를 input 안에 두지 않고 밖에서 실행? input 안에서는 모든 index가 0으로 적용됨. 따라서 정확한 index를 전달할 수 없음
                                            >수정</label>
                                                <input
                                                type= 'file'
                                                onChange={(e) => {changeFiles(e)}}
                                                id='change-file'
                                                className='none'
                                                // multiple
                                            />
                                        </a>
                                        <a onClick={()=>{deleteFiles(i + ((currentPage - 1) * 6))}}>삭제</a>
                                    </ul>
                                </li>
                            )
                        })}
                    </ul> */}

                    <div className={`board__page-number`}>
                        <div className='board__page-arrow' onClick={()=>{clickPrevArrow()}}>&#8249;</div>
                        {pageArr?.slice(arrowNum,(arrowNum + batchOfPages)).map((a, i) => {
                            return(
                                <div
                                    style={{fontWeight: a == currentPage ? '600' :'300'}}
                                    onClick={() => {clickPageNumber(a)}}>
                                        {a}
                                </div>
                            )})}
                        <div className='board__page-arrow' onClick={()=>{clickNextArrow()}}>&#8250;</div>
                    </div>
                </div>
            </div>
        </>
    )
}

