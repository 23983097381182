import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMemo, useRef, useState } from "react";
import { fileAuthInstance } from "../../modules/api/api";
import ImageResize from 'quill-image-resize';
Quill.register('modules/ImageResize', ImageResize);

// Add CSS Code for react-quill
function EditorComponent(props) {
  const value = props.value;
  const onChange = props.onChange;
  const quillRef = useRef(null);

  // Upload Image to AWS S3
  const imageHandler = async (e) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.addEventListener("change", async () => {
      const formData = new FormData()
      formData.append('files', input.files?.[0])
        try {
          const resp = await fileAuthInstance.post('news/files', formData)
          console.log('success >>', resp.data.urls[0]);
          // Attach S3-url to <Img /> Tag
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          editor.insertEmbed(range.index, "image", resp.data.urls[0]);
        } catch (err) {
          console.log('Error >>', err)
        }
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          ["bold", "underline"],
          ['link', 'image'],
          [{ 'align': [] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      /* For Resizing Images */
      ImageResize: {
        parchment: Quill.import('parchment')
      }
    };
  }, []);

  return (
    <>
      <div style={{height: "302px", width: "100%"}}>
        <ReactQuill
          ref={quillRef}
          className='react-quill'
          style={{height: "260px"}}
          modules={modules}
          value={value || ''}
          onChange={(content, delta, source, editor) => onChange(editor.getHTML())}
        />
      </div>
    </>
  );
}

export default EditorComponent;