import axios from 'axios'

const baseURL = 'https://api.eunedu.co.kr'

const baseAPI = axios.create({
    baseURL: baseURL,
    headers:{
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

const token = localStorage.getItem('access_token')
const authAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    }
})

const refreshToken = localStorage.getItem('refresh_token')
const reAuthAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${refreshToken}`,
    }
})

const fileAuthAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${token}`,
    }
})


export const baseInstance = baseAPI;
export const authInstance = authAPI;
export const reAuthInstance = reAuthAPI;
export const fileAuthInstance = fileAuthAPI;