import { Route, Routes } from 'react-router-dom';
import { Intro, Footer, Header,
        Introduction, Ceo, Service,
        Board,
        Contact,
        AdminList, AdminSignin,
        BoardWrite, BoardModify, BoardDetail,
        License
        } from './routes';


function App() {
  return (
    
    <div className="App">
        <Header />
        <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/license" element={<License />} />

            <Route path="/company">
              <Route path="introduction" element={<Introduction />}  />
              <Route path="ceo" element={<Ceo />}  />
              <Route path="service" element={<Service />}  />
            </Route>

            <Route path="/board" element={<Board />} />
            <Route path="/board/detail/:id" element={<BoardDetail />}  />
            <Route path="/contact" element={<Contact />} />

            <Route path="/admin">
              <Route path="list" element={<AdminList />}  />
              <Route path="" element={<AdminSignin />}  />
              <Route path="board/write" element={<BoardWrite />}  />
              <Route path="board/modify/:id" element={<BoardModify />}  />
            </Route>
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
